@import './_colors.scss';
@import './_header.scss';
@import './_button.scss';
@import './_footer.scss';

// html {
//   background-color: #DADFF7;
// }

.header-push-down {
  padding-top: 150px;
  padding-bottom: 150px;
}

.frontpageContainer{
  background: rgb(218,223,247);
  background: linear-gradient(145deg, rgba(218,223,247,1) 38%, rgba(0,206,255,1) 100%);
}

.compositionPage{
  //background: rgb(238,202,175);
  //background: radial-gradient(circle, rgba(238,202,175,1) 0%, rgba(148,187,233,1) 100%);
  //background: rgb(238,202,175);
  //background: radial-gradient(circle, rgba(238,202,175,1) 0%, rgba(218,223,247,1) 45%);
  background: rgb(218,223,247);
  //background: radial-gradient(circle, rgba(218,223,247,1) 85%, rgba(0,206,255,1) 100%);
}

.App {
  text-align: center;
  //background: rgb(218,223,247);
  //background: linear-gradient(145deg, rgba(218,223,247,1) 38%, rgba(0,206,255,1) 100%);
}

.blurBox{
  // Glass
  background: rgba(255, 255, 255, 0.41);
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
}

.sigPad {
  border: 2px solid black;
  // Make more responsive
  width:300px;
  height:150px;
}

.inline {
  display: inline-block;
}
