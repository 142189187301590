.btn {
  background-color: $accent-color;
  border: none;
  border-radius: 3px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1em;
  cursor: pointer;
  -webkit-box-shadow: 0px 15px 20px 0px $shadow-color;
  -moz-box-shadow: 0px 15px 20px 0px $shadow-color;
  box-shadow: 0px 15px 20px 0px $shadow-color;
  transition: transform 0.3s ease-in-out 0s;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn:active {
  transform: translateY(3px);
}

.underline > * {
  display: inline-block;
  position: relative;
  color: $text-light-color;
  text-decoration: none;
}
.underline > *:hover {
  color: $text-light-color;
}
.underline > *:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
.underline > *:active:before {
  transform: scaleX(0.5);
}
.underline > *:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $text-light-color;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.dark-underline > * {
  display: inline-block;
  position: relative;
  color: $text-dark-color;
  text-decoration: none;
}
.dark-underline > *:hover {
  color: $text-dark-color;
}
.dark-underline > *:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
.dark-underline > *:active:before {
  transform: scaleX(0.5);
}
.dark-underline > *:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: $text-dark-color;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
