// $background-color: #DADFF7;
$background-color: white;
$text-light-color: #fff;
$text-dark-color: #000;
// $accent-color: #232C33;
$accent-color: #3089e6;
$container-color: #f1f2f6;
$high-light-color: #fff;
$shadow-color:#ced6e0; 
$button-shadow-color: #00326710; 
$alert-color: #D84141

